<style>
@media (max-width: 1024px) {
	.sm-spacer {
		margin-top: .5rem;
	}
}
</style>
<script>
import {VclBulletList, VclCode, VclFacebook, VclList} from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";
import TimeAgo from "vue2-timeago";
import {Game} from "@/enums";
import {OrbitSpinner} from "epic-spinners";
import {get_acsrf_token} from "@/methods";

export default {
	props: ['cftools_id', 'options'],
	components: {
		VclFacebook,
		VclCode,
		VclList,
		VclBulletList,
	},
	computed: {
		...mapGetters([
			'getUILanguage',
			'getDTLocale'
		]),
		rows() {
			return this.weapons.length;
		},
	},
	methods: {
		timeSince(date) {
			var seconds = Math.floor((new Date() - date) / 1000);
			var interval = seconds / 31536000;
			interval = seconds / 86400;
			if (interval > 1) {
				return Math.floor(interval) + " d";
			}
			interval = seconds / 3600;
			if (interval > 1) {
				return Math.floor(interval) + " h";
			}
			interval = seconds / 60;
			if (interval > 1) {
				return Math.floor(interval) + " m";
			}
			return Math.floor(seconds) + " s";
		},
		currentDateTime(date) {
			if (!date || date === null) date = new Date();
			return `${date.getFullYear()}-${(date.getMonth() + 1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
		},
		handleError: function (error) {
			console.log(`[ERROR] ${error}`);
			this.error = true;
		},
		async getData() {
			try {
				let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/intelligence/title/dayz`);

				let response = await fetch(url, {
					method: 'GET',
					credentials: 'include'
				});

				if (response.ok) {
					let data = await response.json();
					this.player = data;
					this.server = data;
					if (this.server.omega.game && this.server.omega.game.general) {
						this.dataHitZones = [];
						this.chartHitZones.labels = [];
						if (this.server.omega.game.general.zones) {
							let totalHits = 0;
							Object.keys(this.server.omega.game.general.zones).forEach((zone) => {
								if (zone === '_total' || zone === '') return;
								let zoneData = this.server.omega.game.general.zones[zone];
								if (zoneData.hits === 0) return;
								totalHits += zoneData.hits;
							});
							Object.keys(this.server.omega.game.general.zones).forEach((zone) => {
								if (zone === '_total' || zone === '') return;
								let zoneData = this.server.omega.game.general.zones[zone];
								if (zoneData.hits === 0) return;
								this.dataHitZones.push(zoneData.hits);

								let zoneAccuracy = this.$n(((zoneData.hits || 0) / Math.max(totalHits || 0, 1) * 100.00).toFixed(1));
								this.chartHitZones.labels.push(this.$t(`profile.server.gamestats.zones.${zone}`) + ` - ${zoneAccuracy} %`);
							});
						}

						this.weapons = [];
						if (this.server.omega.game.general.weapons) {
							Object.keys(this.server.omega.game.general.weapons).forEach((weapon) => {
								let weaponData = this.server.omega.game.general.weapons[weapon];
								if (weaponData.hits === 0) return;
								weaponData.weapon = weapon;
								this.weapons.push(weaponData);
							});
						}
						this.totalRows = this.weapons.length;
					}
					this.$nextTick(() => {
						this.ready = true;
					});
				} else {
					if (response.status === 403) {
						this.upsell = true;
						return;
					}
					throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				}

			} catch (error) {
				this.handleError(error);
			}
		},
		overridePersona(personaName, avatar) {
			this.$parent.$emit('overridePersona', personaName, avatar);
		},
		timeTrick(seconds) {
			return new Date(Date.now() - 1000 * seconds);
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		}
	},
	created() {

	},
	mounted() {
		this.getData();
	},
	data() {
		return {
			Game: Game,
			ready: false,
			upsell: false,
			error: false,
			server: null,
			chartHitZones: {
				chart: {
					height: '100%',
					width: '100%',
					type: 'pie',
				},
				labels: [],
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							height: 200,
						},
						legend: {
							position: 'bottom'
						}
					}
				}],
				colors: [
					'#4285F4',
					'#564ab1',
					'#e83e8c',
					'#f46a6a',
					'#f1b44c',
					'#34c38f',
					'#50a5f1',
					'#c3cbe4',
					'#32394e',
					'#eff2f7',
					'#fff'
				]
			},
			dataHitZones: [],
			weapons: [],
			weaponDetails: [
				{
					key: "weapon",
					sortable: true,
					label: this.$t('profile.server.gamestats.weapons.weapon')
				},
				{
					key: "kills",
					sortable: true,
					label: this.$t('profile.server.gamestats.weapons.kills')
				},
				{
					key: "hits",
					sortable: true,
					label: this.$t('profile.server.gamestats.weapons.hits')
				},
				{
					key: "longest_shot",
					sortable: true,
					label: this.$t('profile.server.gamestats.weapons.longest_shot')
				},
				{
					key: "longest_kill",
					sortable: true,
					label: this.$t('profile.server.gamestats.weapons.longest_kill')
				}
			],
			totalRows: 1,
			currentPage: 1,
			perPage: 10,
			filter: null,
			filterOn: [],
			sortBy: "kills",
			sortDesc: false,
			player: null
		}
	}
};
</script>

<template>
	<div>
		<template v-if="ready">
			<template v-if="!player.omega.status">
				<div class="row mt-4">
					<div class="col justify-content-center">
						<div  class="info-component text-center align-middle mt-auto mb-auto">
							<div class="info-component-space">
								<i class="fad fa-chart-network text-warning info-component-icon"></i>
							</div>
							<div class="row" style="margin-top: 20px;">
								<div class="col-lg-12">
									<h3 v-b-tooltip.hover>{{ $t('profile.intelligence.none.title') }}</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="row mb-4 pb-0">
					<div class="col-lg" v-if="player && player.omega.game.general">
						<div class="card mini-stats-wid mb-0 pb-0">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium mb-2 text-uppercase text-wrap-overflow">
											{{ $t('profile.intelligence.dayz.pvp.kd') }}
										</p>
										<h4 class="mb-0">
											{{ $n(player.omega.game.general.kills || 0) }}
											/
											{{ $n(player.omega.game.general.deaths || 0) }}
											<span class="ml-1 text-muted">
										    ({{ $n( ((player.omega.game.general.kills || 0) / Math.max(player.omega.game.general.deaths || 0, 1) * 1.00).toFixed(2) ) }})
									    </span>
										</h4>
									</div>
									<div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
								    <span class="avatar-title">
									    <i class="fas fa-skull font-size-24"/>
								    </span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg" v-if="player && player.omega.game.general">
						<div class="card mini-stats-wid mb-0 pb-0">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium mb-2 text-uppercase text-wrap-overflow">
											{{ $t('profile.intelligence.dayz.pvp.longest_kill') }}
											({{ $t('profile.intelligence.dayz.pvp.longest_shot') }})
										</p>
										<h4 class="mb-0">
											{{ player.omega.game.general.longest_kill }} m
											<span class="ml-1 text-muted">
												({{ player.omega.game.general.longest_shot }} m)
											</span>
										</h4>
									</div>
									<div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
								    <span class="avatar-title">
									    <i class="fas fa-ruler font-size-24"/>
								    </span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg" v-if="player && player.omega.game.dayz && player.omega.game.dayz.distance_traveled">
						<div class="card mini-stats-wid mb-0 pb-0">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium mb-2 text-uppercase text-wrap-overflow">
											{{ $t('profile.intelligence.dayz.pve.distance_traveled') }}
										</p>
										<h4 class="mb-0">
											<template v-if="player.omega.game.dayz.distance_traveled > 10000">
												{{ $n(Math.round(player.omega.game.dayz.distance_traveled / 1000)) }} km
											</template>
											<template v-else>
												{{ $n(Math.round(player.omega.game.dayz.distance_traveled)) }} m
											</template>

										</h4>
									</div>
									<div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
								    <span class="avatar-title">
									    <i class="fas fa-hiking font-size-24"/>
								    </span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg" v-if="player && player.omega.game.dayz && player.omega.game.dayz.shots">
						<div class="card mini-stats-wid mb-0 pb-0">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium mb-2 text-uppercase text-wrap-overflow">
											{{ $t('profile.intelligence.dayz.pvp.shots_fired') }}
											({{ $t('profile.intelligence.dayz.pvp.accuracy') }})
										</p>
										<h4 class="mb-0">
											{{ $n(player.omega.game.dayz.shots.fired) }}
											<span class="ml-1 text-muted">
												<template v-if="player.omega.game.dayz.shots.hit === null || player.omega.game.dayz.shots.hit === 0 || (player.omega.game.dayz.shots.fired && player.omega.game.dayz.shots.hit > player.omega.game.dayz.shots.fired)">
													(0.00 %)
												</template>
												<template v-else>
													({{ $n(( (player.omega.game.dayz.shots.hit || 0) / Math.max(player.omega.game.dayz.shots.fired || 0, 1) * 100.00).toFixed(2)) }} %)
												</template>

									    </span>
										</h4>
									</div>
									<div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
								    <span class="avatar-title">
									    <i class="fas fa-crosshairs font-size-24"/>
								    </span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg" v-if="player && player.omega.game.dayz && player.omega.game.dayz.kills">
						<div class="card mini-stats-wid mb-0 pb-0">
							<div class="card-body">
								<div class="d-flex">
									<div class="flex-grow-1">
										<p class="text-muted fw-medium mb-2 text-uppercase text-wrap-overflow">
											{{ $t('profile.intelligence.dayz.pve.kills_infected') }}
										</p>
										<h4 class="mb-0">
											{{ $n(player.omega.game.dayz.kills.infected) }}
										</h4>
									</div>
									<div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
								    <span class="avatar-title">
									    <i class="fas fa-biohazard font-size-24"/>
								    </span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="player && player.omega.game.general">
					<div class="col-lg-12 col-sm-12">
						<div class="card card-body">
							<div class="row mt-1 text-center">
								<div class="col">
									<h5 class="font-size-15">{{ $n(player.omega.game.general.suicides || 0) }}</h5>
									<p class="text-muted text-uppercase mb-0">{{ $t('profile.intelligence.dayz.pvp.suicides') }}</p>
								</div>
								<div class="col">
									<h5 class="font-size-15">{{ $n(player.omega.game.general.environment_deaths || 0) }}</h5>
									<p class="text-muted text-uppercase mb-0">{{ $t('profile.intelligence.dayz.pvp.environment_deaths') }}</p>
								</div>
								<div class="col">
									<h5 class="font-size-15">{{ $n(player.omega.game.general.explosion_deaths || 0) }}</h5>
									<p class="text-muted text-uppercase mb-0">{{ $t('profile.intelligence.dayz.pvp.explosion_deaths') }}</p>
								</div>
								<div class="col">
									<h5 class="font-size-15">{{ $n(player.omega.game.general.infected_deaths || 0) }}</h5>
									<p class="text-muted text-uppercase mb-0">{{ $t('profile.intelligence.dayz.pvp.infected_deaths') }}</p>
								</div>
							</div>

							<div class="row mt-3">
								<div class="col-lg-3 col-sm-12" v-if="player && player.omega.game.dayz">
									<div class="table-responsive">
										<table class="table table-nowrap mb-0">
											<tbody>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.fired') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.shots.fired) }}
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.hit') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.shots.hit) }}
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.hit_animals') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.shots.hit_animals) }}
														<small class="text-muted">
															<i class="fal fa-crosshairs " v-b-tooltip.hover :title="$t('profile.intelligence.dayz.pvp.relative_accuracy')"/>
															<template v-if="player.omega.game.dayz.shots.hit_animals === null || player.omega.game.dayz.shots.hit_animals === 0">
																0.00 %
															</template>
															<template v-else>
																{{ $n(( (player.omega.game.dayz.shots.hit_animals || 0) / Math.max(player.omega.game.dayz.shots.fired || 0, 1) * 100.00).toFixed(2)) }} %
															</template>
														</small>
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.hit_infected') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.shots.hit_infected) }}
														<small class="text-muted">
															<i class="fal fa-crosshairs" v-b-tooltip.hover :title="$t('profile.intelligence.dayz.pvp.relative_accuracy')"/>
															<template v-if="player.omega.game.dayz.shots.hit_infected === null || player.omega.game.dayz.shots.hit_infected === 0">
																0.00 %
															</template>
															<template v-else>
																{{ $n(( (player.omega.game.dayz.shots.hit_infected || 0) / Math.max(player.omega.game.dayz.shots.fired || 0, 1) * 100.00).toFixed(2)) }} %
															</template>
														</small>
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.hit_vehicles') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.shots.hit_vehicles) }}
														<small class="text-muted">
															<i class="fal fa-crosshairs" v-b-tooltip.hover :title="$t('profile.intelligence.dayz.pvp.relative_accuracy')"/>
															<template v-if="player.omega.game.dayz.shots.hit_vehicles === null || player.omega.game.dayz.shots.hit_vehicles === 0">
																0.00 %
															</template>
															<template v-else>
																{{ $n(( (player.omega.game.dayz.shots.hit_vehicles || 0) / Math.max(player.omega.game.dayz.shots.fired || 0, 1) * 100.00).toFixed(2)) }} %
															</template>
														</small>
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.hit_players') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.shots.hit_players) }}
														<small class="text-muted">
															<i class="fal fa-crosshairs" v-b-tooltip.hover :title="$t('profile.intelligence.dayz.pvp.relative_accuracy')"/>
															<template v-if="player.omega.game.dayz.shots.hit_players === null || player.omega.game.dayz.shots.hit_players === 0 || (player.omega.game.dayz.shots.fired && player.omega.game.dayz.shots.hit_players > player.omega.game.dayz.shots.fired)">
																0.00 %
															</template>
															<template v-else>
																{{ $n(( (player.omega.game.dayz.shots.hit_players || 0) / Math.max(player.omega.game.dayz.shots.fired || 0, 1) * 100.00).toFixed(2)) }} %
															</template>
														</small>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div class="col-lg-3 col-sm-12" v-if="player && player.omega.game.dayz">
									<b-modal
											id="weaponModal"
											ref="weaponModal"
											size="lg"
											:title="$t('profile.server.gamestats.generic.weapon_stats')"
											title-class="font-18"
											hide-footer
									>
										<div class="row">
											<!-- Search -->
											<div class="col-sm-12 col-md-6">
												<div
														id="tickets-table_filter"
														class="dataTables_filter text-md-end"
												>
													<label class="d-inline-flex align-items-center">
														Search
														<b-form-input
																v-model="filter"
																type="search"
																class="form-control form-control-sm ms-2 ml-1"
														></b-form-input>
													</label>
												</div>
											</div>
											<!-- End search -->
										</div>
										<!-- Table -->
										<div class="table-responsive mb-0">
											<b-table
													class="datatables"
													:items="weapons"
													:fields="weaponDetails"
													responsive="sm"
													:per-page="10"
													:current-page="currentPage"
													:sort-by.sync="sortBy"
													:sort-desc.sync="sortDesc"
													:filter="filter"
													:filter-included-fields="filterOn"
													@filtered="onFiltered"
											></b-table>
										</div>
										<div class="row">
											<div class="col">
												<div
														class="dataTables_paginate paging_simple_numbers float-end"
												>
													<ul class="pagination pagination-rounded mb-0">
														<!-- pagination -->
														<b-pagination
																v-model="currentPage"
																:total-rows="rows"
																:per-page="10"
														></b-pagination>
													</ul>
												</div>
											</div>
										</div>
									</b-modal>

									<div class="table-responsive">
										<div class="table-responsive">
											<table class="table table-nowrap mb-0">
												<tbody>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.kills_players') }}
													</th>
													<td>
														{{ $n(player.omega.game.general.kills || 0) }}
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.kills_infected') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.kills.infected) }}
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.kills_animals') }}
													</th>
													<td>
														{{ $n(player.omega.game.dayz.kills.animals) }}
													</td>
												</tr>
												<tr>
													<th scope="row">
														{{ $t('profile.intelligence.dayz.pvp.unique_weapons') }}
													</th>
													<td>
														{{ $n(weapons.length) }}
													</td>
												</tr>
												<tr>
													<td colspan="2">
														<button class="btn btn-primary btn-block" v-b-modal.weaponModal>
															{{ $t('profile.server.gamestats.generic.weapon_stats') }}
														</button>
													</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div class="col-lg-6 col-sm-12">
									<div>
										<apexchart height="250px" type="pie" :options="chartHitZones" :series="dataHitZones" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else-if="upsell">
			<div class="row mt-4">
				<div class="col justify-content-center">
					<div class="info-component text-center align-middle mt-auto mb-auto">
						<div class="info-component-space">
							<i class="fad fa-chart-network text-info info-component-icon"></i>
						</div>
						<div class="row" style="margin-top: 20px;">
							<div class="col-lg-12">
								<h3 v-b-tooltip.hover>{{ $t('profile.intelligence.upsell.title') }}</h3>
								<h5 class="text-muted mb-2">{{ $t('profile.intelligence.upsell.text', {plan: 'Community'}) }}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="error">
			<div class="row">
				<div class="col-lg-12 col-sm-12">
					<div class="card border border-danger">
						<div class="card-header bg-transparent border-danger">
							<h5 class="my-0 text-danger">
								<i class="far fa-exclamation-circle mr-3"></i>
								{{ $t('error.server.generic.title') }}
							</h5>
						</div>
						<div class="card-body pt-0">
							<h5 class="card-title mt-0"> {{ $t('error.server.generic.component') }}</h5>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="row">
				<div class="col-lg-11">
					<!-- Content row one -->
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="card">
								<div class="card-body">
									<VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="card">
								<div class="card-body">
									<VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
